import React, { useMemo } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import process from 'process'
import toast from 'react-hot-toast'

export default function UseRecaptcha() {
    const recaptchaRef = React.useRef<ReCAPTCHA>(null)
    const siteKey = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY

    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current?.executeAsync()

        if (token) {
            return token
        } else {
            toast.error('Spiacenti, si è verificato un errore, riprova!')

            return null
        }
    }

    const RecaptchaComponent = useMemo(() => {
        if (siteKey) {
            return (
                siteKey && (
                    <>
                        <ReCAPTCHA ref={recaptchaRef} size='invisible' sitekey={siteKey} />
                    </>
                )
            )
        }
    }, [siteKey, recaptchaRef])

    return { recaptchaRef, RecaptchaComponent, onSubmitWithReCAPTCHA }
}

// ** React Imports
import React, { useState, ReactNode, useMemo, useEffect } from 'react'

// ** Next Imports
import Link from 'next/link'

// ** MUI Components
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import useMediaQuery from '@mui/material/useMediaQuery'
import OutlinedInput from '@mui/material/OutlinedInput'
import { styled, useTheme } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import Typography, { TypographyProps } from '@mui/material/Typography'
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import QRCode from 'react-qr-code'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// ** Hooks
import useBgColor from 'src/@core/hooks/useBgColor'
import { useSettings } from 'src/@core/hooks/useSettings'

// ** Layout Import
import BlankLayout from 'src/@core/layouts/BlankLayout'

// ** Demo Imports
import FooterIllustrationsV2 from 'src/views/pages/auth/FooterIllustrationsV2'
import { ErrCallbackType, LoginParams, UserType } from '../../context/types'

import authConfig from '../../configs/auth'
import { RequestMethod } from '../../types/enums/RequestMethod'
import { setAuthLoading, setCredentials } from '../../store/slices/auth'
import { useDispatch } from 'react-redux'
import useCustomErrorHandling from '../../hooks/useCustomErrorHandling'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import process from 'process'
import store, { AppDispatch } from '../../store'
import { fetchCurrentRolePermissions } from '../../store/slices/permissions'
import CircularProgress from '@mui/material/CircularProgress'

import useSvgLogo from '../../hooks/useSvgLogo'
import toast from 'react-hot-toast'
import UseRecaptcha from '../../hooks/useRecaptcha'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import usePageTitleHook from '../../hooks/usePageTitleHook'
import apiClient from '../../configs/axiosConfig'
import Palette from '../../@core/theme/palette'

// ** Styled Components
const LoginIllustrationWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    padding: theme.spacing(20),
    paddingRight: '0 !important',
    [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(10)
    }
}))

const LoginIllustration = styled('img')(({ theme }) => ({
    maxWidth: '48rem',
    [theme.breakpoints.down('xl')]: {
        maxWidth: '38rem'
    },
    [theme.breakpoints.down('lg')]: {
        maxWidth: '30rem'
    }
}))

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 400
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 450
    }
}))

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('md')]: {
        maxWidth: 400
    }
}))

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontWeight: 600,
    letterSpacing: '0.18px',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { marginTop: theme.spacing(8) }
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary
    }
}))
const one_time_password_regex = /^[0-9]+$/
const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(5).required(),
    one_time_password: yup
        .string()
        .matches(one_time_password_regex, "E' possibile inserire solo numeri")
        .default(null)
        .min(6, 'Il campo deve avere almeno 6 caratteri')
        .nullable('Non è possibile lasciare il campo vuoto')
        .required('Non è possibile lasciare il campo vuoto')
})
const schemaWithoutTwoFactor = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(5).required()
})
const defaultValues = {
    password: '',
    email: '',
    one_time_password: null
}

interface FormData {
    email: string
    password: string
    one_time_password: string | null
}

const LoginPage = () => {
    const [rememberMe, setRememberMe] = useState<boolean>(true)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const Translations = dynamic(() => import('../../layouts/components/Translations'))
    const [twoStepRequired, setTwoStepRequired] = useState<boolean>(false)
    const [twoStepsData, setTwoStepData] = useState<{ qr: string | null; code: string | null }>({ qr: null, code: null })
    const [twoStepConfigurationVisible, setTwoStepConfigurationVisible] = useState<boolean>(false)

    // ** Hooks
    const theme = useTheme()
    const { settings } = useSettings()
    const hidden = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch: AppDispatch = useDispatch()

    // ** Vars
    const { skin } = settings

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues,
        mode: 'onBlur',
        resolver: yupResolver(!twoStepRequired ? schemaWithoutTwoFactor : schema)
    })
    const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL
    const { handleError, setError } = useCustomErrorHandling({ onLoadingComplete: () => console.log(false) })
    const router = useRouter()
    const { HeadTitleComponent } = usePageTitleHook({ entity: 'Login' })
    const twoStepQrCode = useMemo(() => {
        if (twoStepsData.qr) {
            return (
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <QRCode value={twoStepsData.qr} />
                </div>
            )
        } else {
            return <></>
        }
    }, [twoStepsData.qr])

    const twoStepCode = useMemo(() => {
        if (twoStepsData.code) {
            return (
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', padding: 6 }}>
                    <Typography sx={{ color: 'primary', textDecoration: 'none' }}>{twoStepsData.code}</Typography>
                </div>
            )
        } else {
            return <></>
        }
    }, [twoStepsData.code])
    const handleLogin = async (params: LoginParams, errorCallback?: ErrCallbackType) => {
        setError(undefined)
        apiClient.defaults.withCredentials = true
        await apiClient
            .get(baseUrl + authConfig.setCsrf)
            .then(async response => {
                const payload = {
                    email: params.email,
                    password: params.password,
                    remember: params.rememberMe,
                    recaptcha_token: params.token
                }
                if (response) {
                    try {
                        const loginResponse = await apiClient(baseUrl + authConfig.login, {
                            method: RequestMethod.POST,
                            data: !twoStepRequired
                                ? payload
                                : {
                                      ...payload,
                                      one_time_password: params.one_time_password
                                  },
                            headers: {
                                Accept: 'application/json'
                            }
                        })
                        if (loginResponse && loginResponse.status === 206) {
                            toast('Per questo account è richiesta la 2FA', {
                                icon: '🔒'
                            })
                            setTwoStepRequired(true)
                            setTwoStepData({
                                qr: loginResponse.data.qr_code_url ?? null,
                                code: loginResponse.data.secret ?? null
                            })
                        } else if (loginResponse && loginResponse.data.user) {
                            console.log('LoginResponse', loginResponse)
                            dispatch(
                                setCredentials({
                                    user: loginResponse.data.user
                                })
                            )
                            await dispatch(fetchCurrentRolePermissions(loginResponse.data.user.role_id))
                            dispatch(setAuthLoading(false))
                            const returnUrl = router.query.returnUrl
                            const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
                            await router.replace(redirectURL as string)

                            return response.data
                        }
                    } catch (e: any) {
                        dispatch(setAuthLoading(false))
                        if (errorCallback) errorCallback(e)
                        await handleError({ ...e.response?.data, code: e.response?.status })
                    }
                }
            })
            .catch(e => {
                console.log('ErrorHandleLogin', e)
            })
    }

    const { RecaptchaComponent, onSubmitWithReCAPTCHA, recaptchaRef } = UseRecaptcha()

    const onSubmit = async (data: FormData) => {
        if (recaptchaRef?.current) {
            try {
                const { email, password, one_time_password } = data
                const token = await onSubmitWithReCAPTCHA()

                if (twoStepRequired && one_time_password) {
                    await handleLogin({ email, password, rememberMe, token, one_time_password })
                } else {
                    await handleLogin({ email, password, rememberMe, token })
                }
            } catch (e) {
                toast.error('Spiacenti, si è verificato un errore, riprova!')
                console.log(e)
            } finally {
                recaptchaRef.current?.reset()
            }
        } else {
            toast.error('Spiacenti, si è verificato un errore, riprova!')
        }
    }
    const { svgTextLogo, svgIconLogo } = useSvgLogo()
    const imageSource = skin === 'bordered' ? 'auth-v2-login-illustration-bordered' : 'auth-v2-login-illustration'
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { version } = require('../../../package.json')

    return (
        <Box className='content-right'>
            {HeadTitleComponent()}
            {!hidden ? (
                <Box sx={{ flex: 1, display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'center' }}>
                    <LoginIllustrationWrapper>
                        <LoginIllustration alt='login-illustration' src={`/images/pages/${imageSource}-${theme.palette.mode}.png`} />
                    </LoginIllustrationWrapper>
                    <FooterIllustrationsV2 />
                </Box>
            ) : null}
            <RightWrapper sx={skin === 'bordered' && !hidden ? { borderLeft: `1px solid ${theme.palette.divider}` } : {}}>
                <Box
                    sx={{
                        p: 7,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'background.paper'
                    }}
                >
                    <BoxWrapper>
                        <Box
                            sx={{
                                top: 30,
                                left: 40,
                                display: 'flex',
                                position: 'absolute',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            {svgIconLogo}
                            <Box sx={{ paddingLeft: 4, paddingTop: 6 }}>{svgTextLogo}</Box>
                            {/*TODO:replace with svg logo + text <Typography variant='h6' sx={{ ml: 2, lineHeight: 1, fontWeight: 700, fontSize: '1.5rem !important' }}>
                                Rushers
                            </Typography>*/}
                        </Box>
                        <Box sx={{ mb: 6 }}>
                            <TypographyStyled variant='h5'>
                                <Translations text={'WelcomeMessage'} />
                                {` 👋🏻`}
                            </TypographyStyled>
                            <Typography variant='body2'>Accedi al tuo account e inizia l'avventura</Typography>
                        </Box>
                        <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                            <FormControl fullWidth sx={{ mb: 4 }} required={true}>
                                <Controller
                                    name='email'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <TextField
                                            autoFocus
                                            label='Email'
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            required={true}
                                            error={Boolean(errors.email)}
                                            placeholder='admin@materialize.com'
                                        />
                                    )}
                                />
                                {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
                            </FormControl>
                            <FormControl fullWidth required={true}>
                                <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                                    Password
                                </InputLabel>
                                <Controller
                                    name='password'
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { value, onChange, onBlur } }) => (
                                        <OutlinedInput
                                            value={value}
                                            onBlur={onBlur}
                                            label='Password'
                                            onChange={onChange}
                                            id='auth-login-v2-password'
                                            error={Boolean(errors.password)}
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton edge='end' onMouseDown={e => e.preventDefault()} onClick={() => setShowPassword(!showPassword)}>
                                                        <Icon icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'} fontSize={20} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    )}
                                />
                                {errors.password && (
                                    <FormHelperText sx={{ color: 'error.main' }} id=''>
                                        {errors.password.message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            {twoStepRequired && (
                                <FormControl fullWidth sx={{ mt: 4 }} required={true}>
                                    <InputLabel htmlFor='one_time_password' error={Boolean(errors.one_time_password)}>
                                        2FA
                                    </InputLabel>
                                    <Controller
                                        name='one_time_password'
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { value, onChange, onBlur } }) => (
                                            <OutlinedInput
                                                autoFocus
                                                required={true}
                                                value={value}
                                                onBlur={onBlur}
                                                label='2FA'
                                                onChange={onChange}
                                                placeholder={'Inserisci il codice di verifica'}
                                                id='one_time_password'
                                                error={Boolean(errors.one_time_password)}
                                                type={'number'}
                                            />
                                        )}
                                    />
                                    {errors.one_time_password && (
                                        <FormHelperText sx={{ color: 'error.main' }} id=''>
                                            {errors.one_time_password.message}
                                        </FormHelperText>
                                    )}

                                    <Typography
                                        variant='body2'
                                        component={Link}
                                        href={'#'}
                                        sx={{ color: theme.palette.warning.main, mt: 3, textDecoration: 'none', textAlign: 'center' }}
                                        onClick={() => setTwoStepConfigurationVisible(!twoStepConfigurationVisible)}
                                    >
                                        Associa authenticator
                                    </Typography>
                                </FormControl>
                            )}
                            <Dialog
                                open={twoStepConfigurationVisible}
                                onClose={() => setTwoStepConfigurationVisible(false)}
                                aria-labelledby='twoStepConfig'
                                aria-describedby='twoStepConfig-description'
                                sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: 650 } }}
                            >
                                <DialogTitle
                                    id='twoStepConfig-filters'
                                    sx={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem !important',
                                        px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
                                        pt: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
                                    }}
                                >
                                    Configura l'app Authenticator
                                </DialogTitle>
                                <DialogContent
                                    sx={{
                                        pb: theme => `${theme.spacing(8)} !important`,
                                        px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`]
                                    }}
                                >
                                    <DialogContentText id='twoStepConfig-description' sx={{ textAlign: 'center', mb: 7, fontWeight: 'bold' }}>
                                        Scannerizza QR code
                                    </DialogContentText>
                                    {twoStepConfigurationVisible && (
                                        <Box display={'flex'} flexDirection={'column'}>
                                            {twoStepQrCode}
                                            <Divider sx={{ mt: 6, mb: 6 }}></Divider>
                                            <DialogContentText id='twoStepConfig-description' sx={{ textAlign: 'center', mb: 3, fontWeight: 'bold' }}>
                                                Oppure utilizza questo codice
                                            </DialogContentText>
                                            {twoStepCode}
                                        </Box>
                                    )}
                                    <DialogActions
                                        sx={{
                                            justifyContent: 'center',
                                            px: theme => [`${theme.spacing(5)} !important`, `${theme.spacing(15)} !important`],
                                            pb: theme => [`${theme.spacing(8)} !important`, `${theme.spacing(12.5)} !important`]
                                        }}
                                    >
                                        <Button variant='outlined' color='error' onClick={() => setTwoStepConfigurationVisible(false)}>
                                            Chiudi
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>
                            {RecaptchaComponent}
                            <Box
                                sx={{
                                    mb: 4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <FormControlLabel label='Ricordami' control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />} />
                                <Typography variant='body2' component={Link} href='/forgot-password' sx={{ color: 'primary.main', textDecoration: 'none' }}>
                                    Password dimenticata?
                                </Typography>
                            </Box>
                            <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 7 }}>
                                Login
                            </Button>
                            {version && (
                                <Typography variant='body2' sx={{ color: 'primary.main', textAlign: 'center', textDecoration: 'none' }}>
                                    {'v.' + version}
                                </Typography>
                            )}
                        </form>
                        {store.getState().auth.loading && <CircularProgress color={'primary'} />}
                    </BoxWrapper>
                </Box>
            </RightWrapper>
        </Box>
    )
}

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

LoginPage.guestGuard = true
export default LoginPage

import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { CustomError } from '../types/http/CustomError'
import { useAuth } from './useAuth'

interface useCustomErrorHandlingProps {
    onLoadingComplete: () => void
}

export default function useCustomErrorHandling(props: useCustomErrorHandlingProps) {
    const { onLoadingComplete } = props
    const [error, setError] = useState<CustomError | undefined>(undefined)
    const auth = useAuth()
    const handleError = useCallback(async (customError: unknown) => {
        const error = customError as CustomError
        console.log(error)
        if (error.errors) {
            // @ts-ignore
            toast.error(error?.errors ? Object.values(error?.errors).flat().join('\n') : error?.message, {
                duration: 2000
            })
        } else {
            toast.error(error.message, { duration: 2000 })
        }
        if (error.code === 401) {
            await auth.logout()
        }

        setError(error)
        onLoadingComplete()
        console.log(JSON.stringify(error))
        throw JSON.stringify(error)
    }, [])

    return { handleError, error, setError }
}

import React, { useCallback, useMemo } from 'react'
import Head from 'next/head'

interface usePageTitleHookProps {
    entity: string
}
export default function usePageTitleHook({ entity }: usePageTitleHookProps) {
    const env = process.env.NEXT_PUBLIC_API_BASE_URL
    const isStageEnvironment = useMemo(() => {
        if (env) {
            return env.includes('backend.stage')
        }
    }, [env])
    const HeadTitleComponent = useCallback(() => {
        if (entity.length) {
            return (
                <Head>
                    {entity.length ? (
                        <>
                            <title> {isStageEnvironment ? entity + ' | Rushers Dev' : entity + ' | Rushers'}</title>
                            <meta property='og:title' content={entity} key='title' />
                        </>
                    ) : (
                        <>
                            <title> {isStageEnvironment ? 'Rushers Dev' : 'Rushers'}</title>
                            <meta property='og:title' content={'Rushers'} key='title' />
                        </>
                    )}
                </Head>
            )
        }
    }, [entity, isStageEnvironment])

    return { HeadTitleComponent }
}
